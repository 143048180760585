<template>
  <div class="content">
    <div class="content__title content__title--party">
      <h1 class="title title--big title--theme">Список заявок на перемещение</h1>
    </div>
    <div class="content__title">
      <h2 class="title title--theme">Региональная организация: {{ regionOrgName }}</h2>
    </div>
    <div class="content__title">
      <router-link :to="{ name: 'journal-moving-create' }" class="link">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
          <path
            d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
            fill="white"/>
        </svg>
        <span>Создать заявку</span>
      </router-link>
    </div>
    <div class="content__tabs">
      <div class="tabs-list">
        <ul v-if="userRole === 'admin'" class="tabs-list__body">
          <li class="tabs-list__item">
            <router-link :to="{ name: 'journal-moving-list-inner' }" exact-active-class="tabs-list__link--active"
                         class="tabs-list__link">Принятые
            </router-link>
          </li>
          <li class="tabs-list__item">
            <router-link :to="{ name: 'journal-moving-list-outer' }" exact-active-class="tabs-list__link--active"
                         class="tabs-list__link">На рассмотрении
            </router-link>
          </li>
        </ul>
        <ul v-else class="tabs-list__body">
          <li class="tabs-list__item">
            <router-link :to="{ name: 'journal-moving-list-inner' }" exact-active-class="tabs-list__link--active"
                         class="tabs-list__link">Входящие
            </router-link>
          </li>
          <li class="tabs-list__item">
            <router-link :to="{ name: 'journal-moving-list-outer' }" exact-active-class="tabs-list__link--active"
                         class="tabs-list__link">Исходящие
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <router-view class="content__body"/>
    </transition>
  </div>
</template>

<script>
import jwt from '@/http/requests/auth';

export default {
  name: 'MovingLayout',
  async created() {
    jwt.init()
  },
  computed: {
    regionOrgName() {
      return this.$store.state.organizationName
    },
    userRole() {
      return this.$store.state.userRole
    },
  },
}
</script>
